// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/SeoMetaTags/SeoMetaTags.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/SeoMetaTags/SeoMetaTags.tsx");
  import.meta.hot.lastModified = "1730048705000";
}
// REMIX HMR END

export const SeoMetaTags = () => {
  return <>
      <meta name='format-detection' content='telephone=no' />
      <meta name='viewport' content='width=device-width, initial-scale=1, maximum-scale=1' />
      <link rel='shortcut icon' href='/favicon.ico' type='image/x-icon' />
      <link rel='icon' href='/favicon.ico' type='image/x-icon' />
      <meta name='theme-color' content='#ffffff' media='(prefers-color-scheme: light)' />
      <meta name='theme-color' content='#ffffff' media='(prefers-color-scheme: dark)' />
      <meta property='og:locale' content='en_US' />
      <meta property='og:type' content='article' />
      <meta property='og:title' content='ESG and Sustainability Performance Management Solutions' />
      <meta property='og:description' content='ESG and Sustainability Performance Management Solutions, Track, optimize, and report on environmental, social, and governance performance for a sustainable future. Elevate your brand, and attract investors.' />
      <meta property='og:url' content='https://www.samcorporate.com/esg/' />
      <meta property='og:site_name' content='SAM Corporate' />
      <meta property='og:updated_time' content='2023-09-07T12:31:22+00:00' />
      <meta property='og:image' content='https://www.samcorporate.com/wp-content/uploads/2023/06/ESG-1180x670-1.jpg' />
      <meta property='og:image:secure_url' content='https://www.samcorporate.com/wp-content/uploads/2023/06/ESG-1180x670-1.jpg' />
      <meta property='og:image:width' content='1180' />
      <meta property='og:image:height' content='670' />
      <meta property='og:image:alt' content='ESG and Sustainability Performance Management Solutions' />
      <meta property='og:image:type' content='image/jpeg' />
      <meta name='twitter:card' content='summary_large_image' />
      <meta name='twitter:title' content='ESG and Sustainability Performance Management Solutions' />
      <meta name='twitter:description' content='ESG and Sustainability Performance Management Solutions, Track, optimize, and report on environmental, social, and governance performance for a sustainable future. Elevate your brand, and attract investors.' />
      <meta name='twitter:image' content='https://www.samcorporate.com/wp-content/uploads/2023/06/ESG-1180x670-1.jpg' />
      <meta name='twitter:label1' content='Time to read' />
      <meta name='twitter:data1' content='2 minutes' />
    </>;
};
_c = SeoMetaTags;
var _c;
$RefreshReg$(_c, "SeoMetaTags");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;