// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/root.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$(),
  _s2 = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/root.tsx");
}
// REMIX HMR END

import { cssBundleHref } from '@remix-run/css-bundle';
import { json } from '@remix-run/node';
import { Links, LiveReload, Meta, Outlet, Scripts, ScrollRestoration, useLoaderData, useRouteError } from '@remix-run/react';
import { useEffect } from 'react';
import { getFlashMessage } from '~/api/utilities/flashMessage.server';
import { getUserFromSession } from '~/api/utilities/authSession.server';
import { showToast } from '~/lib/toasts';
import { Toaster } from 'react-hot-toast';
import { commitSession } from '~/api/utilities/session.server';
import { SeoMetaTags } from '~/components/SeoMetaTags';
import appStyles from '~/assets/styles/app.css';
import keenSliderCss from 'keen-slider/keen-slider.min.css';
import slickStyles from 'slick-carousel/slick/slick.css';
import contentStyles from './assets/styles/contentstyles.css';
import { AppStoreProvider } from './components/Slider/_provider/SliderContext';
import { AuthProvider } from '~/components/Auth/AuthContext';
export const links = () => [...(cssBundleHref ? [{
  rel: 'stylesheet',
  href: cssBundleHref
}] : []), {
  rel: 'stylesheet',
  href: appStyles
}, {
  rel: 'stylesheet',
  href: keenSliderCss
}, {
  rel: 'stylesheet',
  href: slickStyles
},
// { rel: 'stylesheet', href: emailInputStyles },
{
  rel: 'stylesheet',
  href: contentStyles
}, {
  rel: 'preconnect',
  href: 'https://fonts.googleapis.com'
}, {
  rel: 'preconnect',
  href: 'https://fonts.gstatic.com',
  crossOrigin: 'anonymous'
}, {
  rel: 'stylesheet',
  href: 'https://fonts.googleapis.com/css2?family=Alexandria:wght@100..900&display=swap'
}];
export async function loader({
  request
}) {
  const {
    message: toastMessage,
    session
  } = await getFlashMessage(request);
  const user = await getUserFromSession(request, true);
  const isLoggedIn = !!user?.id;
  const isProduction = process.env.ENV_MODE === 'production';
  return json({
    toastMessage,
    user,
    isLoggedIn,
    isProduction
  }, {
    headers: {
      'Set-Cookie': await commitSession(session)
    }
  });
}
export const meta = () => {
  return [{
    title: 'ESG and Sustainability Performance Management Solutions'
  }, {
    name: 'description',
    content: 'ESG and Sustainability Performance Management Solutions, Track, optimize, and report on environmental, social, and governance performance for a sustainable future. Elevate your brand, and attract investors.'
  }];
};
export function ErrorBoundary() {
  _s();
  const error = useRouteError();
  return <html>
      <head>
        <title>Oh no!</title>
        <Meta />
        <Links />
      </head>
      <body>
        <main>
          <p className='text-white bg-red-500 p-6'>{error?.message}</p>
          <div className='p-6 text-center bg-red-500 text-white'>
            <p>{error?.stack}</p>
          </div>
        </main>
        <Scripts />
      </body>
    </html>;
}
_s(ErrorBoundary, "RcsYcqLg6saPxU0a5GB4CRLeqZA=", false, function () {
  return [useRouteError];
});
_c = ErrorBoundary;
export default function App() {
  _s2();
  const {
    toastMessage,
    user = null,
    isLoggedIn = false
  } = useLoaderData();
  useEffect(() => {
    if (!toastMessage) {
      return;
    }
    if (toastMessage?.type && toastMessage?.message) {
      showToast(toastMessage.message, toastMessage.type);
    }
  }, [toastMessage]);
  const initialStoreValue = {
    isOpen: false

    // other initial state values
  };
  return <html lang='en'>
      <head>
        <meta charSet='utf-8' />
        <meta name='viewport' content='width=device-width,initial-scale=1' />
        <SeoMetaTags />
        <Meta />
        <Links />
      </head>
      <body>
        <AppStoreProvider value={initialStoreValue}>
          <AuthProvider user={user} isLoggedIn={isLoggedIn}>
            <Outlet />
          </AuthProvider>
        </AppStoreProvider>
        <Toaster />
        <ScrollRestoration />
        <Scripts />
        <LiveReload />
      </body>
    </html>;
}
_s2(App, "C/LbgvKtmUiXLHPKQV/aksrt+ps=", false, function () {
  return [useLoaderData];
});
_c2 = App;
var _c, _c2;
$RefreshReg$(_c, "ErrorBoundary");
$RefreshReg$(_c2, "App");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;